<template>
    <div class="MaterialSci">
        <p class="p justify">
            我们团队具有多年的物理过程仿真模拟技术的积淀，熟悉各种材料模拟相关软件，可以根据客户的实际需求将问题简化建模，采用合理的算法用专业软件解决物理问题，对于模拟的结果可以从理论上给予定性的判断，从而保证结果的可靠性。
        </p>
        <div class="img-info-div">
            <img
                class="img-info"
                src="@/assets/images/materia/sci/cailiao-keyan.png"
            >
        </div>
        <b-row class="text-center">
            <span class="material-sci-img-info">材料分类以及计算模拟服务种类示意图</span>
        </b-row>
        <p class="p justify">
            在一些特殊环境中，材料的性质会发生改变，有些甚至出现新型的材料状态或结构。这时材料的性质会与我们经验感知的有很大差别。针对这些新型材料，没有现成的商业化软件工具可用时，就可以依据团队成员的物理背景和项目经验，设计相关物理模型，编写模拟计算代码，开发定制功能模块来应用于这些特殊的场景。
        </p>
    </div>
</template>

<script>
export default {
  name: 'MaterialSci',
  data () {
        return {

        }
      },
      methods: {

      },
      created () {
        this.$emit('contentTitle', '材料设计与器件仿真解决方案 - 科研方向')
      }
}
</script>
<style lang="less" scoped>
.MaterialSci{
    margin-bottom:6.25rem;
    .img-info-div{
      width: 100%;
      text-align: center;
      .img-info{
        width: 50%;
      }
    }

    .material-sci-img-info{
      font-size:0.875rem ;
      color: gray;
      margin-bottom: 1.875rem;
    }
}
</style>
